import { SvgIcon, SvgIconProps } from "@mui/material";

const BlueLogoWithBlackText = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 159 33">
      <g id="Group-10" transform="translate(44 5)">
        <path
          d="M15.6816 15.7551L13.4414 15.7551L13.4414 13.5005C11.8 15.3423 9.91054 16.2624 7.77173 16.2624C5.63249 16.2624 3.8029 15.4596 2.28165 13.8527C0.760836 12.2462 0 10.3298 0 8.10309C0 5.8764 0.765113 3.96961 2.29577 2.38142C3.82599 0.79366 5.6928 0 7.89618 0C10.0991 0 11.948 0.911336 13.4414 2.73401L13.4414 0.394869L15.6816 0.394869L15.6816 15.7551ZM7.93767 14.0924C9.46789 14.0924 10.7864 13.5284 11.8928 12.4009C12.9988 11.2738 13.5522 9.8691 13.5522 8.18764C13.5522 6.50618 13.0078 5.09712 11.9202 3.95958C10.8322 2.82292 9.5004 2.25459 7.92398 2.25459C6.34757 2.25459 5.01493 2.84253 3.92735 4.01624C2.83934 5.19082 2.29577 6.57679 2.29577 8.17326C2.29577 9.77104 2.84875 11.1566 3.95515 12.3307C5.06155 13.5053 6.38863 14.0924 7.93767 14.0924L7.93767 14.0924Z"
          transform="translate(-5.5422335E-14 5.2272)"
          id="Fill-28"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
        <path
          d="M0 20.9088L2.3232 20.9088L2.3232 0L0 0L0 20.9088Z"
          transform="translate(18.5856 7.1054274E-15)"
          id="Fill-29"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
        <path
          d="M15.6816 12.6271C14.8759 13.8108 13.8216 14.7126 12.5201 15.3328C11.2177 15.953 9.8079 16.2624 8.29037 16.2624C5.92976 16.2624 3.95766 15.4831 2.37495 13.9237C0.791359 12.3638 0 10.4566 0 8.20202C0 5.94744 0.796139 4.01624 2.38885 2.41018C3.98113 0.803684 6.04232 0 8.57154 0C10.0704 0 11.4519 0.329493 12.7165 0.986736C13.9811 1.64441 14.9693 2.5649 15.6816 3.74907L13.8547 4.90404C12.487 3.11929 10.6979 2.22713 8.48723 2.22713C6.74459 2.22713 5.28355 2.78587 4.10281 3.9038C2.92294 5.02172 2.33279 6.40768 2.33279 8.06125C2.33279 9.71481 2.91295 11.1335 4.075 12.3168C5.23662 13.5009 6.70721 14.0924 8.48723 14.0924C10.6232 14.0924 12.4214 13.1906 13.8829 11.3871L15.6816 12.6271Z"
          transform="translate(23.232 5.2272)"
          id="Fill-30"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
        <path
          d="M13.3584 20.9088L11.039 20.9088L11.039 13.6603C11.039 12.248 10.9971 11.2534 10.9133 10.6775C10.8294 10.1015 10.638 9.55793 10.3402 9.04664C10.042 8.53579 9.63667 8.14521 9.12456 7.87577C8.61202 7.60677 7.99706 7.4714 7.28011 7.4714C6.56273 7.4714 5.87777 7.6391 5.22607 7.97363C4.57352 8.30774 4.01431 8.77246 3.5493 9.36738C3.08301 9.9623 2.76191 10.5939 2.58516 11.2629C2.40755 11.932 2.31939 13.2701 2.31939 15.2773L2.31939 20.9088L0 20.9088L0 0L2.31939 0L2.31939 8.00122C3.82848 6.2169 5.61717 5.32495 7.68505 5.32495C8.78445 5.32495 9.78144 5.60818 10.6756 6.17508C11.5697 6.74241 12.2404 7.52313 12.6877 8.51682C13.135 9.51137 13.3584 11.0396 13.3584 13.1029L13.3584 20.9088Z"
          transform="translate(41.2368 7.1054274E-15)"
          id="Fill-31"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
        <path
          d="M15.6816 8.48357L2.34385 8.48357C2.43662 10.1746 2.99971 11.5327 4.03183 12.5565C5.06438 13.5807 6.29672 14.0924 7.72926 14.0924C10.1659 14.0924 12.0265 12.8803 13.3097 10.4562L15.2631 11.4712C14.3884 13.1056 13.3559 14.3133 12.1658 15.093C10.9749 15.8728 9.55187 16.2624 7.89668 16.2624C5.60849 16.2624 3.7203 15.4687 2.23209 13.8805C0.743455 12.2932 0 10.3816 0 8.14536C0 5.90908 0.748633 3.99271 2.2459 2.3958C3.74317 0.79889 5.599 0 7.81297 0C10.0265 0 11.8772 0.756178 13.3654 2.26853C14.8536 3.78176 15.6259 5.85286 15.6816 8.48357L15.6816 8.48357ZM13.1147 6.42599C12.8355 5.11106 12.2029 4.08685 11.217 3.35377C10.231 2.62113 9.09622 2.25459 7.81297 2.25459C5.20807 2.25459 3.47823 3.64534 2.62302 6.42599L13.1147 6.42599Z"
          transform="translate(56.9184 5.2272)"
          id="Fill-32"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
        <path
          d="M22.6512 15.6816L20.2964 15.6816L20.2964 7.34995C20.2964 6.02207 20.2167 5.04948 20.0584 4.43261C19.8989 3.81531 19.5534 3.29605 19.0211 2.87526C18.4883 2.45447 17.7878 2.24407 16.9186 2.24407C16.0494 2.24407 15.2314 2.52474 14.4659 3.08565C13.699 3.64656 13.1662 4.40919 12.8676 5.37223C12.5684 6.33572 12.4189 7.76163 12.4189 9.65042L12.4189 15.6816L10.1482 15.6816L10.1482 7.85447C10.1482 6.32183 10.0685 5.2178 9.91022 4.54454C9.75113 3.87127 9.40043 3.31991 8.85899 2.88914C8.31668 2.45924 7.63435 2.24407 6.81244 2.24407C5.99009 2.24407 5.20936 2.50175 4.47154 3.01581C3.73286 3.5303 3.18622 4.20357 2.83162 5.0356C2.47658 5.86807 2.29885 7.24714 2.29885 9.17323L2.29885 15.6816L0 15.6816L0 0.393027L2.29885 0.393027L2.29885 2.55294C3.60671 0.851124 5.24231 0 7.20476 0C8.26986 0 9.237 0.28501 10.1062 0.855462C10.9753 1.42635 11.5965 2.23496 11.9706 3.28217C12.6057 2.21631 13.4003 1.40249 14.3536 0.841147C15.3064 0.280672 16.3156 0 17.3807 0C18.839 0 20.0814 0.514492 21.1097 1.54261C22.1371 2.57159 22.6512 4.50723 22.6512 7.34995L22.6512 15.6816Z"
          transform="translate(74.9232 5.2272)"
          id="Fill-33"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
        <path
          d="M14.52 0L5.58672 20.9088L3.20842 20.9088L6.11233 14.1092L0 0L2.3783 0L7.32907 11.3044L12.1139 0L14.52 0Z"
          transform="translate(98.736 5.808)"
          id="Fill-34"
          fill="#0C0C2A"
          fillRule="evenodd"
          stroke="#0C0C2A"
          strokeWidth="0.5"
        ></path>
      </g>
      <path
        d="M16.4343 0L19.2773 0L4.55717 25.7748C4.44575 25.9691 4.44633 26.2082 4.55891 26.4019C4.67207 26.5957 4.87866 26.7152 5.10324 26.7152L27.3105 26.7152L28.7827 29.2059L0.0313367 29.2059L16.4343 0ZM0 30.4631L29.8853 30.4631C30.1111 30.4631 30.3194 30.3418 30.4314 30.1457C30.544 29.9496 30.5417 29.7089 30.4268 29.5145L19.0318 10.2422L20.3485 7.95574L35.1615 32.67L1.16642 32.67L0 30.4631ZM36.2455 32.033L20.8778 6.39163C20.7634 6.20076 20.5824 6.08298 20.3329 6.08588C20.11 6.08762 19.9046 6.20772 19.7938 6.40091L8.81605 25.4574L6.18667 25.4574L20.3688 0.624253L37.51 29.7152L36.2455 32.033Z"
        id="Fill-35"
        fill="#024CD0"
        fillRule="evenodd"
        stroke="none"
      ></path>
    </SvgIcon>
  );
};

export default BlueLogoWithBlackText;
