import axios, { AxiosResponse } from "axios";

import baseUrl from "../../../app.config";
import { getUserToken } from "../../../user-application/authentication/helpers";

export const getApplicationData = async (): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl}/api/application/user`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  });
};

export const getPracticeManagementId = async (
  id: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl}/api/admin/dashboard/application/link/${id}`);
};

export const runCreditReport = async () => {
  return axios.post(
    `${baseUrl}/api/application/creditBureauInquiry`,
    { hardPull: false },
    {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    }
  );
};

export const getApplicationLinkData = async (applicationLinkId: string) => {
  return axios.get(
    `${baseUrl}/api/admin/dashboard/application/link/${applicationLinkId}`
  );
};

export const getPaymentSchedule = async (
  token: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl}/api/application/getpaymentschedule/${token}`);
};

export const refundPaymentData = async (requestBody: {
  token: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl}/api/application/refundPayment`, requestBody, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });
};

export const createConsents = async (): Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl}/api/application/consents`, undefined, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });
};

export const updateUserPasswordAndPhones = async (requestBody: {
  password: string;
  phones?: { phone: string; type: string }[];
}): Promise<AxiosResponse<any>> => {
  return axios.patch(`${baseUrl}/api/application/user`, requestBody, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });
};
