import { useNavigate, Link } from "react-router-dom";
import { Button, Box, Container } from "@mui/material";

import BlueLogoWithBlackText from "../../../components/AlchemyBlueLogoWithBlackText";
import { isUserAuthenticated } from "../../authentication/helpers";

interface HeaderOptions {
  showLoginButton: boolean;
}

const Header = ({ showLoginButton }: HeaderOptions) => {
  const hasUserToken = isUserAuthenticated();
  const navigate = useNavigate();

  const onClick = () => {
    if (hasUserToken) {
      localStorage.removeItem("userToken");
      navigate("/login");
    } else {
      navigate("/login");
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
      }}
    >
      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between", py: 3 }}>
          <Link to="/">
            <BlueLogoWithBlackText sx={{ width: 159, height: 33 }} />
          </Link>
          {showLoginButton && (
            <Button
              variant={hasUserToken ? "outlined" : "contained"}
              onClick={onClick}
            >
              {hasUserToken ? "Logout" : "Login"}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
