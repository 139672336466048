import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

type severity = "success" | "error" | "warning" | "info";

interface Notification {
  shouldNotify: boolean;
  severity: severity;
  message: string;
}

const initialState: Notification = {
  shouldNotify: false,
  severity: "success",
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<Notification>) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.shouldNotify = action.payload.shouldNotify;
    },
  },
});

export const { showNotification } = notificationSlice.actions;
export const selectShouldNotify = (state: RootState) =>
  state.notification.shouldNotify;

export default notificationSlice.reducer;
