export const isAdminAuthenticated = (): boolean => {
  const adminToken = localStorage.getItem("adminToken");
  if (!adminToken) {
    return false;
  }

  const { token, role } = JSON.parse(adminToken);
  if (!token) {
    return false;
  }
  switch (role) {
    case "Manager":
    case "Merchant":
    case "Super Admin":
    case "Merchant Staff":
      return true;
    default:
      return false;
  }
};

export const getAdminToken = (): string | undefined => {
  const adminToken = localStorage.getItem("adminToken");
  if (!adminToken) {
    return;
  } else {
    const { token } = JSON.parse(adminToken);

    return token;
  }
};
