import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { showNotification } from "../store/notificationSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={5} ref={ref} variant="filled" {...props} />;
});

const NotificationBar = () => {
  const isOpen: boolean = useAppSelector(
    (state) => state.notification.shouldNotify
  );
  const severity = useAppSelector((state) => state.notification.severity);
  const message = useAppSelector((state) => state.notification.message);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(showNotification({ shouldNotify: false, severity, message }));
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationBar;
