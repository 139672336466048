import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import UserApplicationLayout from "./user-application/layout/components/Layout";
import UserLogin from "./user-application/authentication/pages/Login";
import RequireUserAuthentication from "./user-application/authentication/components/RequireAuthentication";
import RequireAdminAuthentication from "./admin-dashboard/authentication/components/RequireAdminAuthentication";
import RequireSuperAdminAuthentication from "./admin-dashboard/authentication/components/RequireSuperAdminAuthentication";
import RequireSuperAdminOrMerchantAuthentication from "./admin-dashboard/authentication/components/RequireSuperAdminOrMerchantAuthentication";
import RequireMerchantAuthentication from "./admin-dashboard/authentication/components/RequireMerchantAuthentication";

// user application
const Apply = React.lazy(
  () => import("./user-application/application/pages/Apply")
);
const HomeLandingPage = React.lazy(
  () => import("./user-application/landing-page/pages/Home")
);
const LearnMore = React.lazy(
  () => import("./user-application/landing-page/pages/LearnMore")
);
const DeniedOffer = React.lazy(
  () => import("./user-application/offers/pages/Denied")
);
const FlinksConnect = React.lazy(
  () => import("./user-application/flinks/pages/FlinksConnect")
);
const Offers = React.lazy(
  () => import("./user-application/offers/pages/Offers")
);
const OfferReview = React.lazy(
  () => import("./user-application/offers/pages/ReviewOffer")
);
const SignContract = React.lazy(
  () => import("./user-application/sign-contract/pages/Contract")
);
const ForgotUserPassword = React.lazy(
  () => import("./user-application/authentication/pages/ForgotPassword")
);
const ResetUserPassword = React.lazy(
  () => import("./user-application/authentication/pages/ResetPassword")
);
const Repayment = React.lazy(
  () => import("./user-application/repayment/pages/Repayment")
);
const DocumentUpload = React.lazy(
  () => import("./user-application/document-upload/pages/DocumentUpload")
);
const CloseLoan = React.lazy(
  () => import("./user-application/application/pages/CloseLoanConfirmation")
);

// user dashboard
const UserDashBoardLayout = React.lazy(
  () => import("./user-application/dashboard/layout/UserDashboardLayout")
);
const UserCards = React.lazy(
  () => import("./user-application/dashboard/pages/Cards")
);
const Accounts = React.lazy(
  () => import("./user-application/dashboard/pages/Accounts")
);
const UserChangePassword = React.lazy(
  () => import("./user-application/dashboard/pages/ChangePassword")
);
const CardTransactions = React.lazy(
  () => import("./user-application/dashboard/pages/Transactions")
);
const CreditCardStatements = React.lazy(
  () => import("./user-application/dashboard/pages/Statements")
);
const Payments = React.lazy(
  () => import("./user-application/dashboard/pages/Payments")
);
const PaymentMethod = React.lazy(
  () => import("./user-application/dashboard/pages/PaymentMethod")
);
const LoanInfo = React.lazy(
  () => import("./user-application/dashboard/pages/LoanInfo")
);
const DocumentCenter = React.lazy(
  () => import("./user-application/dashboard/pages/DocumentCenter")
);
const UserInfo = React.lazy(
  () => import("./user-application/dashboard/pages/UserInfo")
);

// admin dashboard
const AdminLogin = React.lazy(
  () => import("./admin-dashboard/authentication/pages/Login")
);
const AdminResetPassword = React.lazy(
  () => import("./admin-dashboard/authentication/pages/ResetPassword")
);
const AdminChangePassword = React.lazy(
  () => import("./admin-dashboard/authentication/pages/ChangePassword")
);
const AdminDashboard = React.lazy(
  () => import("./admin-dashboard/layout/pages")
);
const AdminDashboardHome = React.lazy(
  () => import("./admin-dashboard/home/pages/Home")
);
const CreateApplication = React.lazy(
  () => import("./admin-dashboard/application/pages/CreateApplication")
);
const Opportunities = React.lazy(
  () => import("./admin-dashboard/opportunities/pages/Opportunities")
);
const NeedsReview = React.lazy(
  () => import("./admin-dashboard/needs-review/pages/NeedsReview")
);
const LendingCenter = React.lazy(
  () => import("./admin-dashboard/lending-center/pages/LendingCenter")
);
const ManagePatients = React.lazy(
  () => import("./admin-dashboard/applicants/pages/ManageApplicants")
);
const ManagePractices = React.lazy(
  () => import("./admin-dashboard/practices/pages/ManagePractices")
);
const AuditLogs = React.lazy(
  () => import("./admin-dashboard/audit-log/pages/AuditLog")
);
const LogDetail = React.lazy(
  () => import("./admin-dashboard/audit-log/pages/LogDetail")
);
const LoanSettings = React.lazy(
  () => import("./admin-dashboard/settings/pages/Settings")
);
const LoanDetails = React.lazy(
  () => import("./admin-dashboard/loan-details/pages/LoanDetails")
);
const AddSuperAdmin = React.lazy(
  () => import("./admin-dashboard/practices/pages/AddSuperAdmin")
);
const AddPracticeUser = React.lazy(
  () => import("./admin-dashboard/practices/pages/AddPracticeUser")
);
const ManageMerchantUsers = React.lazy(
  () =>
    import("./admin-dashboard/manage-merchant-users/pages/ManageMerchantUsers")
);
const AddMerchantUser = React.lazy(
  () => import("./admin-dashboard/manage-merchant-users/pages/AddMerchantUser")
);
const AddPractice = React.lazy(
  () => import("./admin-dashboard/practices/pages/AddPractice")
);
const EditPractice = React.lazy(
  () => import("./admin-dashboard/practices/pages/EditPractice")
);

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<></>}>
              <HomeLandingPage />
            </React.Suspense>
          }
        />
        <Route element={<UserApplicationLayout />}>
          <Route
            path="apply"
            element={
              <React.Suspense fallback={<></>}>
                <Apply />
              </React.Suspense>
            }
          />
          <Route
            path="apply/:merchantUrl"
            element={
              <React.Suspense fallback={<></>}>
                <Apply />
              </React.Suspense>
            }
          />
          <Route
            path="apply/link/:linkId"
            element={
              <React.Suspense fallback={<></>}>
                <Apply />
              </React.Suspense>
            }
          />
          <Route
            path="connect-bank-account"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <FlinksConnect />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="offers"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Offers />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="review-offer"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <OfferReview />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="sign-contract"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <SignContract />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="repayment"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Repayment />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="document-upload"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <DocumentUpload />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="forgot-password"
            element={
              <React.Suspense fallback={<></>}>
                <ForgotUserPassword />
              </React.Suspense>
            }
          />
          <Route
            path="reset-password/:token"
            element={
              <React.Suspense fallback={<></>}>
                <ResetUserPassword />
              </React.Suspense>
            }
          />
          <Route
            path="close-loan/:token"
            element={
              <React.Suspense fallback={<></>}>
                <CloseLoan />
              </React.Suspense>
            }
          />
          <Route path="login" element={<UserLogin />} />
        </Route>

        <Route
          path="/:merchantUrl"
          element={
            <React.Suspense fallback={<></>}>
              <HomeLandingPage />
            </React.Suspense>
          }
        />

        <Route
          path="/learn-more"
          element={
            <React.Suspense fallback={<></>}>
              <LearnMore />
            </React.Suspense>
          }
        />

        <Route
          path="denied"
          element={
            <RequireUserAuthentication>
              <React.Suspense fallback={<></>}>
                <DeniedOffer />
              </React.Suspense>
            </RequireUserAuthentication>
          }
        />

        <Route
          path="/dashboard"
          element={
            <RequireUserAuthentication>
              <React.Suspense fallback={<></>}>
                <UserDashBoardLayout />
              </React.Suspense>
            </RequireUserAuthentication>
          }
        >
          <Route
            index
            element={
              <RequireUserAuthentication>
                <Navigate to="/dashboard/cards" replace={true} />
              </RequireUserAuthentication>
            }
          />
          <Route
            path="cards"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <UserCards />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="accounts"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Accounts />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="transactions"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <CardTransactions />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="statements"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <CreditCardStatements />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="payments"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Payments />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="payment-methods"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <PaymentMethod />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="loan-info"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <LoanInfo />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="document-center"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <DocumentCenter />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="user-info"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <UserInfo />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="change-password"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <UserChangePassword />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
        </Route>

        <Route
          path="/admin/dashboard"
          element={
            <RequireAdminAuthentication>
              <React.Suspense fallback={<></>}>
                <AdminDashboard />
              </React.Suspense>
            </RequireAdminAuthentication>
          }
        >
          <Route
            index
            element={
              <RequireAdminAuthentication>
                <Navigate to="/admin/dashboard/home" replace={true} />
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="home"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AdminDashboardHome />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="create-application"
            element={
              <RequireSuperAdminOrMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <CreateApplication />
                </React.Suspense>
              </RequireSuperAdminOrMerchantAuthentication>
            }
          />
          <Route
            path="change-password"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AdminChangePassword />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="opportunities"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <Opportunities />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="needs-review"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <NeedsReview />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="lending-center"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LendingCenter />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="manage-users"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManagePatients />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="manage-merchant"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManagePractices />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="audit-log"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AuditLogs />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="view-log-details/:id"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LogDetail />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="settings"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LoanSettings />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="loan-details/:screenTrackingId"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LoanDetails />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="add-super-admin"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddSuperAdmin />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="edit-super-admin/:id"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddSuperAdmin />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="add-practice-user/:practiceId"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddPracticeUser />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="edit-practice-user/:id"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddPracticeUser />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="add-practice-management"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddPractice />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="edit-practice-management/:id"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <EditPractice />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="merchant/manage-users"
            element={
              <RequireMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManageMerchantUsers />
                </React.Suspense>
              </RequireMerchantAuthentication>
            }
          />
          <Route
            path="merchant/add-user"
            element={
              <RequireMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchantUser />
                </React.Suspense>
              </RequireMerchantAuthentication>
            }
          />
          <Route
            path="merchant/edit-user/:id"
            element={
              <RequireMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchantUser />
                </React.Suspense>
              </RequireMerchantAuthentication>
            }
          />
        </Route>

        <Route
          path="/admin/login"
          element={
            <React.Suspense fallback={<></>}>
              <AdminLogin />
            </React.Suspense>
          }
        />

        <Route
          path="/admin/reset-password"
          element={
            <React.Suspense fallback={<></>}>
              <AdminResetPassword />
            </React.Suspense>
          }
        />

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
