import { Box, Container } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Steps from "./Steps";
import Spinner from "../../../components/Spinner";
import NotificationBar from "../../../components/NotificationBar";

const Layout = () => {
  const { pathname } = useLocation();
  let showLoginButton = true;
  let showSteps = true;

  if (pathname === "/login") {
    showLoginButton = false;
    showSteps = false;
  } else if (
    pathname === "/forgot-password" ||
    pathname.startsWith("/reset-password")
  ) {
    showLoginButton = false;
    showSteps = false;
  } else if (pathname.startsWith("/close-loan")) {
    showLoginButton = false;
    showSteps = false;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "secondary.main",
      }}
    >
      <Header showLoginButton={showLoginButton} />
      <Container sx={{ flex: 1 }}>
        <NotificationBar />
        {showSteps && <Steps />}
        <Outlet />
        <Spinner />
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
