import { Navigate, useLocation } from "react-router-dom";
import { getAdminRoles, getAdminData } from "../../helpers";

import { isAdminAuthenticated } from "../helpers";

const RequireSuperAdminAuthentication = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const isAuthenticated = isAdminAuthenticated();
  const location = useLocation();
  const adminData = getAdminData();
  const adminRoles = getAdminRoles();
  const isSuperAdmin = adminData?.role === adminRoles.SuperAdmin;

  return isAuthenticated && isSuperAdmin ? (
    children
  ) : (
    <Navigate to="/admin/login" state={{ from: location }} />
  );
};

export default RequireSuperAdminAuthentication;
