import { Navigate, useLocation } from "react-router-dom";
import { getAdminRoles, getAdminData } from "../../helpers";

import { isAdminAuthenticated } from "../helpers";

const RequireSuperAdminOrMerchantAuthentication = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const isAuthenticated = isAdminAuthenticated();
  const location = useLocation();
  const adminData = getAdminData();
  const adminRoles = getAdminRoles();
  const isSuperAdmin = adminData?.role === adminRoles.SuperAdmin;
  const isMerchant = adminData?.role === adminRoles.Merchant;

  return (isAuthenticated && isSuperAdmin) ||
    (isAuthenticated && isMerchant) ? (
    children
  ) : (
    <Navigate to="/admin/login" state={{ from: location }} />
  );
};

export default RequireSuperAdminOrMerchantAuthentication;
