import { Navigate, useLocation } from "react-router-dom";

import { isAdminAuthenticated } from "../helpers";

const RequireAdminAuthentication = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const isAuthenticated = isAdminAuthenticated();
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/admin/login" state={{ from: location }} />
  );
};

export default RequireAdminAuthentication;
