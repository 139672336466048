import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";

interface Documents {
  passport: string;
  cardFront: string;
  cardBack: string;
}

const initialState: Documents = {
  passport: "",
  cardFront: "",
  cardBack: "",
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setPassport: (state, action: PayloadAction<string>) => {
      state.passport = action.payload;
    },
    setCardFront: (state, action: PayloadAction<string>) => {
      state.cardFront = action.payload;
    },
    setCardBack: (state, action: PayloadAction<string>) => {
      state.cardBack = action.payload;
    },
  },
});

export const { setPassport, setCardFront, setCardBack } =
  documentsSlice.actions;
export const selectPassport = (state: RootState) => state.documents.passport;
export const selectCardFront = (state: RootState) => state.documents.cardFront;
export const selectCardBack = (state: RootState) => state.documents.cardBack;

export default documentsSlice.reducer;
