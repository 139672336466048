import { Navigate, useLocation } from "react-router-dom";
import { getAdminRoles, getAdminData } from "../../helpers";

import { isAdminAuthenticated } from "../helpers";

const RequireMerchantAuthentication = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const isAuthenticated = isAdminAuthenticated();
  const location = useLocation();
  const adminData = getAdminData();
  const adminRoles = getAdminRoles();
  const isMerchant = adminData?.role === adminRoles.Merchant;

  return isAuthenticated && isMerchant ? (
    children
  ) : (
    <Navigate to="/admin/login" state={{ from: location }} />
  );
};

export default RequireMerchantAuthentication;
