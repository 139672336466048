import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { getYear } from "date-fns";

import logoWhite from "../../../assets/logo-white.png";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#0c0c2a",
        color: "primary.contrastText",
      }}
    >
      <Container sx={{ py: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Link to="/login">
              <img src={logoWhite} alt="Logo" />
            </Link>
          </Box>
          <Typography fontFamily='"Source Sans Pro", sans-serif'>
            &copy;{getYear(new Date())} Alchemy Technologies All rights reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
