import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";

import store from "./store";
import Routes from "./Routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#024cd0",
    },
    secondary: {
      main: "#f8f8f8",
      contrastText: "#767676",
    },
  },
  typography: {
    fontFamily: '"Red Hat Display", sans-serif',
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
