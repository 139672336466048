import { Navigate, useLocation } from "react-router-dom";

import { isUserAuthenticated } from "../helpers";

const RequireUserAuthentication = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = isUserAuthenticated();
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default RequireUserAuthentication;
