import { configureStore } from "@reduxjs/toolkit";

import layoutReducer from "../user-application/layout/store/layoutSlice";
import notificationReducer from "./notificationSlice";
import documentsReducer from "../user-application/document-upload/store/documentUploadSlice";
import loadingReducer from "./loadingSlice";

const store = configureStore({
  reducer: {
    userLayout: layoutReducer,
    notification: notificationReducer,
    documents: documentsReducer,
    loading: loadingReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
