import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as yup from "yup";

import { login } from "../api";
import { getApplicationData } from "../../application/api";
import handleError from "../../../helpers/handleError";
import { useAppDispatch } from "../../../store/hooks";
import { setIsLoading } from "../../../store/loadingSlice";
import { showNotification } from "../../../store/notificationSlice";
import NotificationBar from "../../../components/NotificationBar";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsLoading(false));
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().trim().email().required("Email is required"),
      password: yup.string().trim().required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setIsLoading(true));
        const requestBody = {
          email: values.email.trim().toLowerCase(),
          password: values.password,
        };
        const { data: jwtToken } = await login(requestBody);
        localStorage.setItem("userToken", JSON.stringify(jwtToken));

        const { data: applicationData } = await getApplicationData();
        if (!applicationData.isCompleted) {
          navigate(`/${applicationData.lastStep}`);
        } else {
          navigate("/dashboard");
        }
      } catch (error) {
        const errorMessage = (error as any).message;

        if (errorMessage === "Request failed with status code 401") {
          dispatch(
            showNotification({
              message: "Email or password invalid",
              severity: "error",
              shouldNotify: true,
            })
          );
        } else {
          const errorMessage = handleError(error, navigate);
          if (errorMessage) {
            dispatch(
              showNotification({
                message: errorMessage,
                severity: "error",
                shouldNotify: true,
              })
            );
          }
        }

        dispatch(setIsLoading(false));
      }
    },
  });

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 172px)",
      }}
    >
      <Box sx={{ width: 500, textAlign: "center" }}>
        <NotificationBar />
        <Typography variant="h3" sx={{ mb: 3 }}>
          Alchemy Client Login
        </Typography>
        <Typography variant="h6">
          Access your account information by logging in below.
        </Typography>

        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <TextField
                type="email"
                variant="outlined"
                label="EMAIL"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                label="PASSWORD"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={onClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ my: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={formik.isSubmitting}
                  sx={{ px: 7 }}
                >
                  LOGIN
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Forgot Your Password?{" "}
                <Link
                  to="/forgot-password"
                  component={RouterLink}
                  underline="always"
                >
                  Reset here
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Can't Login?{" "}
                <Link to="/apply" component={RouterLink} underline="always">
                  Start here
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
