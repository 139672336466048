import { NavigateFunction } from "react-router";

const handleError = (
  error: unknown,
  navigate: NavigateFunction,
  isAdmin = false
): string | undefined => {
  let errorMessage = "";

  const castedError = error as Record<string, any>;

  if (castedError?.response?.status === 400) {
    errorMessage =
      castedError.response.data?.error || castedError.response.data?.message;
  } else if (
    castedError?.response?.status === 401 ||
    castedError?.response?.status === 403
  ) {
    localStorage.clear();
    if (isAdmin) {
      navigate(`/admin/login`);
    } else {
      navigate(`/login`);
    }
  } else if (castedError?.response?.status === 500) {
    errorMessage = "Something went wrong. Please try again later.";
  } else {
    errorMessage = castedError.message;
  }

  if (errorMessage) {
    return errorMessage;
  }
};

export default handleError;
